import React from 'react'
import { Card as CardComponent } from 'theme-ui'
import { Layout, Stack, Main } from '@layout'
import CardList from '@components/CardList'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import AuthorExpanded from '@widgets/AuthorExpanded'
import NewsletterExpanded from '@widgets/NewsletterExpanded'
import { PostBody, PostComments } from '@widgets/Post'
import CardPostInfo from '@elegantstack/flow-ui-components/src/Card/Card.Post.Info'
import CardPost from '@elegantstack/flow-ui-components/src/Card/Card.Post'

const Wallet = ({
    data: { wallet, tagCategoryPosts, tagPosts, categoryPosts },
    ...props
  }) => {
  const relatedPosts = [
    ...(tagCategoryPosts ? tagCategoryPosts.nodes : []),
    ...(tagPosts ? tagPosts.nodes : []),
    ...(categoryPosts ? categoryPosts.nodes : [])
  ]
  const { pageContext: { services = {}, siteUrl } = {} } = props
  return (
    <Layout {...props}>
      <Seo {...wallet} siteUrl={siteUrl} />
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <Main>
          <CardPost {...wallet} omitExcerpt />
        </Main>
      </Stack>
      <Stack effectProps={{ fraction: 0 }}>
        <Main>
          <CardComponent variant='paper-lg'>
            <PostBody {...wallet} />
          </CardComponent>
          {(wallet.website || wallet.transactionFees || wallet.currenciesAvailable) &&
          <CardComponent sx={{ mt: '3', p: '4' }}>
            <CardPostInfo post={wallet}/>
          </CardComponent>}
          <Divider />
          <AuthorExpanded author={wallet.author} />
          {services.disqus && (
            <>
              <Divider />
              <PostComments {...wallet} />
            </>
          )}
          <Divider />
          {wallet.category && (
            <CardList
              nodes={relatedPosts}
              variant={['horizontal-md']}
              columns={[1, 2, 3, 3]}
              limit={6}
              title='Related Posts'
              distinct
            />
          )}
          {services.mailchimp && (
            <>
              <Divider />
              <NewsletterExpanded simple />
            </>
          )}
        </Main>
      </Stack>
    </Layout>
  )
}

export default Wallet
