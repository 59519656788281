import { graphql } from 'gatsby'
import WalletPage from '../containers/Wallet'

export default WalletPage

export const pageQuery = graphql`
  query WalletPageQuery(
    $id: String!
    $previousId: String
    $nextId: String
    $categoryId: String
    $currencyId: [String]
    $reviewId: String
  ) {
    wallet: wallet(id: { eq: $id }) {
      ...WalletInformation
      ...WalletThumbnailRegular
      ...WalletLogo
    }

#    # Related posts based on tags and category
#    tagCategoryWallets: allWallet(
#      filter: {
#        private: { ne: true }
#        draft: {ne: true}
#        tags: { elemMatch: { id: { in: $tagsIds } } }
#        category: { id: { eq: $categoryId } }
#        id: { ne: $id }
#      }
#      sort: { fields: [date], order: DESC }
#      limit: 6
#    ) @include(if: $hasTags) {
#      nodes {
#        ...WalletPreview
#        ...WalletThumbnailRegular
#      }
#    }
#
#    # Related exchanges based on tags only
#    tagWallets: allWallet(
#      filter: {
#        private: { ne: true }
#        draft: {ne: true}
#        tags: { elemMatch: { id: { in: $tagsIds } } }
#        id: { ne: $id }
#      }
#      sort: { fields: [date], order: DESC }
#      limit: 6
#    ) @include(if: $hasTags) {
#      nodes {
#        ...WalletPreview
#        ...WalletThumbnailRegular
#      }
#    }

    # Related exchanges based on category only
    categoryWallets: allWallet(
      filter: {
        private: { ne: true }
        draft: {ne: true}
        category: { id: { eq: $categoryId } }
        id: { ne: $id }
      }
      sort: { fields: [date], order: DESC }
      limit: 6
    ) {
      nodes {
        ...WalletPreview
        ...WalletThumbnailRegular
        ...WalletLogo
      }
    }

    # Related exchanges based on country only
    currencyWallets: allWallet(
      filter: {
        private: { ne: true }
        draft: {ne: true}
        currency: { elemMatch: { id: { in: $currencyId } } }
        id: { ne: $id }
      }
      sort: { fields: [date], order: DESC }
      limit: 6
    ) {
      nodes {
        ...WalletPreview
        ...WalletThumbnailRegular
        ...WalletLogo
      }
    }

    # Related exchanges based on review only
    reviewWallets: allWallet(
      filter: {
        private: { ne: true }
        draft: {ne: true}
        ourReview: { id: { eq: $reviewId } }
        id: { ne: $id }
      }
      sort: { fields: [date], order: DESC }
      limit: 6
    ) {
      nodes {
        ...WalletPreview
        ...WalletThumbnailRegular
        ...WalletLogo
      }
    }

    previous: wallet(id: { eq: $previousId }) {
      id
      slug
      title
    }

    next: wallet(id: { eq: $nextId }) {
      id
      slug
      title
    }
  }
`
